<template>
  <b-nav-item-dropdown
    id="dropdown-grouped"
    variant="link"
    class="dropdown-language"
    right
    :disabled="disabled"
  >
    <template #button-content>
      <span :class="color" class="ml-50">{{ currentLocale.name }}</span>
    </template>
    <b-dropdown-item
      v-for="localeObj in locales"
      :key="localeObj.locale"
      :disabled="isDisabled(localeObj)"
      @click="$i18n.locale = localeObj.locale"
    >
      <span class="ml-50">{{ localeObj.name }}</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import { mapGetters } from "vuex";
import { BNavItemDropdown, BDropdownItem } from "bootstrap-vue";
import { locales } from '@models';

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem
  },
  props: {
    disabled: {
      type: Boolean,
    },
    programLanguages: {
      type: Array,
      default: null,
    },
    activeLanguages: {
      type: Array,
      default: null,
    },
    color: {
      type: String,
      default: "text-white"
    }
  },
  // ! Need to move this computed property to comp function once we get to Vue 3
  computed: {
    ...mapGetters("profile", ["profile"]),

    currentLocale() {
      if (
        this.profile.preferred_language &&
        this.profile.preferred_language.code
      ) {
        return this.locales.find(
          l => l.locale === this.profile.preferred_language.code
        );
      }
      return this.locales.find(l => l.locale === this.$i18n.locale);
    },

    locales() {
      if (this.programLanguages && this.programLanguages.length > 0) {
        return this.programLanguages.map(lang => ({
          id: lang.id,
          locale: lang.code,
          name: lang.name,
        }));
      }
      return this.defaultLocales;
    },
  },

  watch: {
    currentLocale: {
      handler (n) {
        this.$emit("select", n);
      },
      deep: true,
      immediate: true
    }
  },

  mounted() {
    const previewLang = this.$route.query.lang;
    if (previewLang) {
      const locale = this.locales.find(l => `${l.id}` === previewLang);
      if (locale) {
        this.$i18n.locale = locale.locale;
      }
    }
  },

  methods: {
    isDisabled(localeObj) {
      if (!this.activeLanguages) {
        return false;
      }

      if (this.activeLanguages.includes(localeObj.id)) {
        return false;
      }

      return true;
    },
  },

  setup() {
    /* eslint-disable global-require */
    const defaultLocales = [
      {
        id: locales.EN,
        locale: "en",
        name: "English",
      },
      {
        id: locales.FR,
        locale: "fr",
        name: "French",
      },
      {
        id: locales.DE,
        locale: "de",
        name: "German",
      },
      {
        id: locales.PT,
        locale: "pt",
        name: "Portuguese",
      },
      {
        id: locales.ES,
        locale: "es",
        name: "Español",
      },
    ];
    /* eslint-disable global-require */

    return {
      defaultLocales,
    };
  },
};
</script>